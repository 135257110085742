import Loading from 'components/Main/Loading';
import Textify from 'components/Main/Textify';
import Header from 'components/Main/Header';
import Footer from 'components/Main/Footer';
import Error from 'components/Main/Error';
import React, { Component } from 'react';
import { getLang } from 'utils/lang';
import { request } from 'utils/';

import Consultancy from './Consultancy';
import B2B from './B2B';
import Festival from './Festival';
import Sustainability from './Sustainability';
import Impressum from 'views/Impressum';

let pages = [];

export default class Page extends Component {
  signal = false

  state = { error: false }

  reloadPages() {
    return request('/pages')
      .then(ps => {
        pages = ps;
        if(this.signal)
          this.forceUpdate();
      })
      .catch(() => {
        if(this.signal)
          this.setState({ error: true });
      })
  }

  componentWillMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  render() {
    const name = window.location.href.split('/').pop();
    const page = pages.find(p => p.name === name);

    if(!this.state.error && !pages.length) this.reloadPages();
    if(this.state.error || !page) return (
      <><Header name={name}  forceUpdate={() => this.forceUpdate()}/>
        <div className="w3-container" style={{ padding: '20px 16px' }}>
          {this.state.error || (pages.length && !page) ? <Error /> : <Loading />}
        </div>
      <Footer /></>
    )

    return (
      <>
        <Header name={name} forceUpdate={() => this.forceUpdate()}/>
          <div className="w3-container" style={{ padding: '20px 16px' }}>
            <div className="w3-row-padding" style={{ marginTop: '30px' }}>
              <div className="w3-quarter">&nbsp;</div>
              <div className="w3-half w3-center w3-animate-bottom">
                {name !== 'impressum' && <Textify text={page[getLang()]} /> }
                {name === 'consultancy' && <Consultancy />}
                {name === 'b2b' && <B2B />}
                {name === 'festival' && <Festival />}
                {name === 'sustainability' && <Sustainability />}
              </div>
              {name === 'impressum' && <Impressum text={page[getLang()]} />}
            </div>
          </div>
        <Footer />
      </>
    )
  }
}
