import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  DashboardOutlined as DashboardIcon,
  PeopleOutlined as PeopleIcon,
  ImageOutlined as ImageIcon,
  AccountBox as AccountBoxIcon
} from '@material-ui/icons';
import styles from './styles';
import { getMe } from '../../../../utils';
import Notify from '../../../../components/Notify';

class Sidebar extends Component {
  render() {
    const { classes, className } = this.props;

    const rootClassName = classNames(classes.root, className);
    const me = getMe();
    if(!me) return Notify.showLoading();

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to="/"
          >
            <img
              alt="Soundsgood Agency"
              className={classes.logoImage}
              src={`${process.env.REACT_APP_BASE_URL}/static/logo.png`}
            />
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        {me.id !== '1' && <div className={classes.profile}>
          <Avatar
            alt={me.username}
            className={classes.avatar}
            src={`${process.env.REACT_APP_BASE_URL}/static/musicians/${me.id}.jpg`}
          />
          <Typography
            className={classes.nameText}
            dangerouslySetInnerHTML={{ __html: me.displayName}}
            variant="h6"
          />
        </div>}
        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/downloads"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Download Area"
            />
          </ListItem>
          {me.id === '1' && <>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/admin/artists"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Artists"
              />
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={NavLink}
              to="/admin/pages"
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Pages"
              />
            </ListItem>
          </>}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/admin/account"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Account"
            />
          </ListItem>
        </List>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);
