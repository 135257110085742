import { sendFiles, FILE_BASE64, FOLDER_BASE64, request, getMe } from 'utils/';
import { withStyles, Typography } from '@material-ui/core';
import Notify from 'components/Notify';
import Card from 'components/Card';
import { TextField } from '@material-ui/core';
import DropZone from 'components/DropZone';
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

class FileOverview extends Component {
  signal = false;

  state = {
    cards: [],
    loading: true,
    success: false,
    error: false
  }

  fetchCards(first = false) {
    const { path } = this.props;
    if(!first) this.setState({ loading: true });
    request(`/files/${path}`)
      .then(files => {
        this.setState({
          loading: false,
          cards: files.map(f => ({
            title: f.name,
            image: f.thumbnail || (f.name.includes('.') ? FILE_BASE64 : FOLDER_BASE64),
            date: f.date,
            link: !f.name.includes('.') && `${f.path.slice('../downloads/'.length)}${f.name}`,
            download: `${process.env.REACT_APP_BASE_URL}/api/v1.php/files/download/${f.path.slice('../downloads/'.length)}${f.name}`,
            delete: `/files/delete/${f.path.slice('../downloads/'.length)}${f.name}`
          }))
        })
      })
      .catch(e => {
        console.error(e) // eslint-disable-line no-console
        this.setState({ loading: false, error: true })
      })
  }

  componentDidUpdate({ path }) {
    if(path !== this.props.path) this.fetchCards();
  }

  componentWillMount() {
    this.signal = true;
    this.fetchCards(true);
  }

  componentWillUnmount() {
    this.signal = false;
  }

  onDrop = files => {
    this.setState({ loading: true });
    sendFiles(files, this.props.path)
      .then(res => {
        if(!res.status) {
          this.setState({ loading: false, error: 'Failed to upload the following files:' + res.files.join(', ') });
          return setTimeout(() => this.fetchCards(), 5000);
        }
        return this.fetchCards();
      })
      .catch(e => {
        console.error(e) // eslint-disable-line no-console
        this.setState({ loading: false, error: 'An unexpected error has occured. Please try again later.' })
      })
  }

  removeFile = card => {
    this.setState({ loading: true, success: false, error: false });
    request(card.delete)
      .then(() => {
        if(this.signal)
          this.setState({ loading: false, error: false, success: true, cards: this.state.cards.filter(f => f.title !== card.title) });
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        if(this.signal)
          this.setState({ loading: false, error: true, success: false });
      })
  }

  addFolder = name => {
    const path = `${this.props.path}/${name}`;
    request(`/files/create/${path}`)
      .then(() => {
        if(this.signal)
          this.setState({ loading: false, error: false, success: true, cards: [...this.state.cards, {
            title: name,
            image: FOLDER_BASE64,
            date: 'Now',
            link: `/${path}`,
            download: `${process.env.REACT_APP_BASE_URL}/api/v1.php/files/download/${path}`,
            delete: `/files/delete/${path}`
          }]});
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
        if(this.signal)
          this.setState({ loading: false, error: true, success: false });
      })
  }

  render() {
    if(this.state.loading) return Notify.showLoading();
    const { classes } = this.props;
    const { cards } = this.state;
    const userID = getMe().id;

    return (
      <>
        {userID === '1' && (
        <>
          <DropZone onDrop={this.onDrop} />
          <TextField
            className={this.props.classes.textField}
            id="createFolder"
            label="Create new folder"
            margin="dense"
            onKeyDown={e => e.key === 'Enter' && this.addFolder(document.getElementById('createFolder').value)}
            variant="outlined"
          />
        </>
        )}
        <div className={classes.content}>
          {Notify.show(this)}
          <Grid
            container
            spacing={3}
          >
            {cards.length === 0 ? <Grid item><Typography> This folder is empty. </Typography></Grid> : cards.map((card, i) => (
              <Grid
                item
                key={`Card${i}`}
                lg={2}
                md={3}
                xs={6}
              >
                <Card
                  data={card}
                  removeFile={userID === '1' && (() => this.removeFile(card))}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </>
    );
  }
}

FileOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
}

export default withStyles(styles)(FileOverview);
