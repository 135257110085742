import Loading from 'components/Main/Loading';
import Header from 'components/Main/Header';
import Footer from 'components/Main/Footer';
import Error from 'components/Main/Error';
import React, { Component } from 'react';
import { request } from 'utils/';
import PropTypes from 'prop-types';
import ArtistCard from './Card';
import './styles.css';
import ArtistPage from './Page';

let artists = [];

export default class Artists extends Component {
  signal = false

  state = {
    error: false,
    enlarged: null
  }

  reloadArtists() {
    return request('/artists')
      .then(data => {
        artists = data;
        if(this.signal)
          this.forceUpdate();
      })
      .catch(() => this.signal && this.setState({ error: true }))
  }

  componentWillMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  render() {
    if(!this.state.error && !artists.length) this.reloadArtists();
    if(this.state.error || !artists.length) return (
      <><Header name="artists" forceUpdate={() => this.forceUpdate()} />
        <div className="w3-container" style={{ padding: '20px 16px' }}>
          {this.state.error ? <Error /> : <Loading />}
        </div>
      <Footer /></>
    )

    const id = this.props.match.params.id;
    const artist = id && artists.find(a => a.id === id);

    return (
      <>
        <Header name="artists" forceUpdate={() => this.forceUpdate()} />
          <div className="w3-container" style={{ padding: '20px 16px' }}>
            {artist && <ArtistPage artist={artist} />}
            {!artist && artists
              .reduce((acc, val, i) => { if(i % 3 === 0) acc.push([val]); else acc[acc.length - 1].push(val); return acc; }, [])
              .map((row, key) => (
                <div key={key} className="w3-row-padding w3-content" style={{ marginTop: '64px'}}>
                  {row.map((artist, key) => <ArtistCard artist={artist} key={key} />)}
                </div>
              ))}
            <br />
            <br />
          </div>
        <Footer />
      </>
    )
  }
}

Artists.propTypes = {
  match: PropTypes.object.isRequired
}
