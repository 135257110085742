import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/core';
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Portlet, PortletContent } from 'components';
import styles from './styles';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

class UsersTable extends Component {
  render() {
    const { classes, className, users, removeUser } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletContent noPadding>
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Artist</TableCell>
                  <TableCell align="left">Username</TableCell>
                  <TableCell align="left">Contact</TableCell>
                  <TableCell align="left">Website</TableCell>
                  <TableCell align="left">Youtube</TableCell>
                  <TableCell align="left"><RemoveCircle /></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user.id}
                  >
                    <TableCell className={classes.tableCell}>
                      <Link to={`/admin/artists/${user.id}`}>
                        <div className={classes.tableCellInner}>
                          <Avatar
                            className={classes.avatar}
                            src={`${process.env.REACT_APP_BASE_URL}/static/musicians/${user.id}.jpg`}
                          />
                          <Typography
                            className={classes.nameText}
                            dangerouslySetInnerHTML={{ __html: user.display_name }}
                            variant="body1"
                          />
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.username}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {user.contact === 'ruben' ? 'Ruben' : 'Friede'}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <a href={user.website}>{user.website}</a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <a href={user.youtube}>{user.youtube}</a>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <RemoveCircle
                        onClick={() => removeUser(user.id, user.username)}
                        style={{ cursor: 'pointer'}}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    );
  }
}

UsersTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  removeUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default withStyles(styles)(UsersTable);
