import { Link } from 'react-router-dom';
import React from 'react';
import __ from 'utils/lang';

export const getNavBarArray = () => [
  ['/artists', __('navbar.artists'), 'fa fa-address-book'],
  ['/consultancy', __('navbar.consultancy'), 'fas fa-hands-helping'],
  ['/b2b', __('navbar.b2b'), 'fas fa-people-carry'],
  ['/festival', __('navbar.festival'), 'fas fa-music'],
  ['/about', __('navbar.about'), 'fa fa-users'],
  ['/contact', __('navbar.contact'), 'fa fa-envelope']
];

export default function NavBar() {
  return getNavBarArray().map(([link, title, image], key) => (
    <Link key={key} to={link} className="w3-bar-item w3-button"><i className={image} /> {title.toUpperCase()}</Link>
  ))
}
