import Loading from 'components/Main/Loading';
import Header from 'components/Main/Header';
import Footer from 'components/Main/Footer';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { request } from 'utils/';
import __ from 'utils/lang';
import './styles.css';

export default class Page extends Component {
  signal = false

  state = { loading: false, error: null, redirect: false }

  login = () => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    if(!username || !password)
      return this.setState({ error: __('login.emptyField') });
    this.setState({ error: null, loading: true });
    return request('/login', 'POST', {
      username, password
    })
      .then(() => this.signal && this.setState({ redirect: true }))
      .catch(e => console.error(e) || (this.signal && this.setState({ error: __('login.invalid'), loading: false }))) // eslint-disable-line no-console
  }

  componentWillMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  render() {
    if(this.state.redirect) {
      return <Redirect push to="/admin" />
    }
    return (
      <>
        <Header name="Login" forceUpdate={() => this.forceUpdate()}/>
          <div className="w3-container" style={{ padding: '20px 16px' }}>
            <div className="w3-row-padding" style={{ marginTop: '30px' }}>
              <div className="w3-third">&nbsp;</div>
              <div className="w3-third w3-center w3-animate-bottom">
                <h1>Artist Login</h1>
                <p>Account details are provided by us: info(at)soundsgoodagency.com</p>
                <hr />

                <label htmlFor="name"><b>Name</b></label>
                <input id="username" type="text" placeholder="Enter Name" name="name" style={{ background: '#f1f1f1' }} required/>

                <label htmlFor="psw"><b>Password</b></label>
                <input id="password" type="password" placeholder="Enter Password" name="pwd" style={{ background: '#f1f1f1' }} required />
                {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                {this.state.loading ? <Loading /> : (
                  <button className="loginbtn" onClick={this.login}> Login </button>
                )}
                <br />
                <br />
              </div>
            </div>
          </div>
        <Footer />
      </>
    )
  }
}
