import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import styles from './styles';
import { ArtistOverview, FileOverview } from './components';
import { getMe, ensureMe } from 'utils/';
import { Redirect } from 'react-router';

class Downloads extends Component {
  render() {
    const { classes } = this.props;
    const userID = getMe().id;
    let path = this.props.location.pathname.slice('/admin/downloads/'.length);

    if(!userID) {
      ensureMe().then(() => this.forceUpdate());
      return <DashboardLayout title="Download Area" />
    }

    if(userID !== '1' && !path) {
      return (
        <Redirect
          push
          to={`/admin/downloads/${userID}`}
        />
      )
    }

    return (
      <DashboardLayout title="Download Area">
        <div className={classes.root}>
          {path ? (
            <FileOverview
              path={path}
              userID={userID}
            />
          ) : <ArtistOverview />}
        </div>
      </DashboardLayout>
    );
  }
}

Downloads.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default withStyles(styles)(Downloads);
