import React from 'react';

export default function Loading() {
  return (
    <div className="w3-center">
      <br /><br />
      <h1><i className="fas fa-circle-notch fa-spin" style={{ color: '#2388CC' }} /></h1>
      <br />
    </div>
  )
}
