import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';

const colors = {
  jazz: '#33b3aa',
  pop: '#ff7a17',
  classic: '#1728ff',
  rock: '#ccbd00',
  electro: '#000078',
  rap: '#ee1cff',
  hiphop: '#ff0e20',
  funk: '#73c900',
  cloudpop: '#830f52',
  world: '#d0006c'
}

export default function ArtistCard({ artist }) {
  return (
    <div className= "w3-third" style={{ paddingLeft: '11px', paddingRight: '11px', maxWidth: '400px' }}>
      <div className="w3-card artistCard w3-animate-zoom w3-display-container">
        <Link to={`/artists/${artist.id}/${artist.username}`} style={{ textDecoration: 'none' }}>
          <img alt="" src={`${process.env.REACT_APP_BASE_URL}/static/musicians/${artist.id}.jpg`} style={{ width: '100%' }} />
          <div className="w3-container">
            <h4 style={{ marginBottom: '0px' }} dangerouslySetInnerHTML={{ __html: artist.display_name }} /> {/* eslint-disable-line react/no-danger */}
            <div style={{ marginTop: '0px', paddingBottom: '8px', opacity: 0.7 }}>
              {[ 'pop', 'classic', 'jazz', 'rock', 'electro', 'rap', 'hiphop', 'funk', 'cloudpop', 'world' ].map((genre, key) => {
                if(artist[genre] === '1') return (
                  <b key={key} className="w3-small" style={{ backgroundColor: colors[genre], color: 'white', padding: '5px', margin: '3px', borderRadius: '5px' }}>&nbsp;{genre.charAt(0).toUpperCase() + genre.slice(1)}&nbsp;</b>
                )
                return '';
              })}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

ArtistCard.propTypes = {
  artist: PropTypes.object.isRequired
}
