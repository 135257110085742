import __ from '../../utils/lang';
import React from 'react';

export default function Festival() {
  return (
    <div className="w3-center" style={{ display: 'inline', margin: 'auto' }}>
      <a href="https://www.soundsgoodfestival.de/" target="_blank" rel="noopener noreferrer" className="w3-bar-item w3-button w3-animate-left" style={{ width: '45%' }}><i className="fas fa-info-circle" />&nbsp;&nbsp;{__('moreInfo')}</a>
    </div>
  )
}
