import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { AccountProfile } from './components';
import { AccountDetails, Quotes, Information } from './Cards';
import Notify from 'components/Notify';
import { request, sendFile } from 'utils/';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
})

class Artist extends Component {
  signal = false;

  state = {
    user: null,
    loading: true,
    success: false,
    error: false
  }

  save = async data => {
    try {
      this.setState({ loading: true });
      await request(`/artists/${this.state.user.id}`, 'POST', data);
      if(this.signal)
        this.setState({ user: { ...this.state.user, ...data }, success: true, error: false, loading: false });
    } catch(e) {
      console.log(e) // eslint-disable-line no-console
      if(this.signal)
        this.setState({ success: false, loading: false, error: true });
    }
  }

  uploadPicture = async files => {
    if(files.length !== 1 || !files[0].name.endsWith('.jpg')) {
      return this.setState({ error: 'Invalid file. Make sure the picture is a .jpg' })
    }
    try {
      this.setState({ loading: true });
      await sendFile(this.state.user.id, files[0]);
      if(this.signal)
        this.setState({ success: true, error: false, loading: false });
    } catch(e) {
      console.log(e) // eslint-disable-line no-console
      if(this.signal)
        this.setState({ success: false, loading: false, error: true });
    }
  }

  componentWillMount() {
    this.signal = true;
    request('/artists').then(users => {
      const user = users.find(u => u.id === window.location.href.split('/').pop());
      if(!user) return window.location.href = '/artists';
      if(this.signal)
        return this.setState({ user, loading: false });
    })
  }

  componentWillUnmount() {
    this.signal = false;
  }

  render() {
    const { user, loading } = this.state;
    const { classes } = this.props;

    if(loading) return (
      <DashboardLayout title="Account">
        <div className={classes.root}>
          {Notify.showLoading()}
        </div>
      </DashboardLayout>
    );

    return (
      <DashboardLayout title="Account">
        {Notify.show(this)}
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <AccountProfile
                    uploadPicture={this.uploadPicture}
                    user={user}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Quotes
                    save={this.save}
                    user={user}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={8}
              xs={12}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <AccountDetails
                    save={this.save}
                    user={user}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Information
                    save={this.save}
                    user={user}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Artist.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Artist);
