import React from 'react';
import Helmet from 'react-helmet';

const en = {
  headers: {
    artists: 'Artists',
    consultancy: 'Consultancy',
    b2b: 'B2B',
    festival: 'Soundsgood Festival',
    sustainability: 'Sustainability',
    about: 'About Us',
    contact: 'Contact',
    login: 'Login',
  },
  navbar: {
    artists: 'Artists',
    consultancy: 'Consultancy',
    b2b: 'B2B',
    festival: 'SG-Festival',
    sustainability: 'Sustainability',
    about: 'About Us',
    contact: 'Contact',
    login: 'Login',
    impressum: 'Legal Notice'
  },
  main: {
    subtitle: 'ABOUT SOUNDSGOOD',
    subtext: {
      consultancy: 'Consulting in the cultural sector',
      artists: 'Fascinating Artists from Pop, Jazz, Classic',
      b2b: 'Wide variety of cultural services'
    },
    icons: {
      title: ['Music', 'Passion', 'Trust', 'Sustainability'],
      text: [
        'Music is our passion. We see culture as a driver of innovation, a mastermind and poetry in once. This also characterizes our artists, who break  new ground in their fields and create impressive sound experiences.',
        'We do everything to achieve the best possible result for our customers and artists. We are 100% committed to each project and we are giving our best to make cultural and musical experiences possible.',
        'Trust is a paramount value for us. Therefore, cooperative, joint work is a imperative aspect of our work. To achieve our common goals communication, transparency, and solution-oriented work is our foundation.',
        'Sustainability is a term that is used rapidly nowadays. However, we take it seriously when talking about long-term relationship with artists and partners, considering ecological aspects, and negotiating fair conditions for all stakeholders.'
      ]
    }
  },
  contact: {
    title: 'Contact',
    location: 'Overath, Germany',
    phoneNumber: '+49 176 / 22277321',
    email: 'info(at)soundsgoodagency.com',
    manager: 'Managing Director'
  },
  footer: {
    facebook: 'Follow us on Facebook'
  },
  error: 'An error occured. Please refresh the page, or try again later.',
  references: 'References',
  requestOrQuestions: 'Request or Questions',
  bandcoaching: 'Bandcoaching',
  moreInfo: 'More Information',
  impressum: {
    title: 'Legal Notice'
  },
  login: {
    emptyField: 'Please enter a username and password.',
    invalid: 'You\'ve entered an invalid combination of username and password.',
  }
}



const de = {
  headers: {
    artists: 'Künstler',
    consultancy: 'Consultancy',
    b2b: 'B2B',
    festival: 'Soundsgood Festival',
    sustainability: 'Nachhaltigkeit',
    about: 'Über Soundsgood',
    contact: 'Kontakt',
    login: 'Login',
  },
  navbar: {
    artists: 'Künstler',
    consultancy: 'Consultancy',
    b2b: 'B2B',
    festival: 'SG-Festival',
    sustainability: 'Nachhaltigkeit',
    about: 'Über uns',
    contact: 'Kontakt',
    login: 'Login',
    impressum: 'Impressum'
  },
  main: {
    subtitle: 'ÜBER SOUNDSGOOD',
    subtext: {
      consultancy: 'Beratung im Kultursektor',
      artists: 'Spannende Künstler aus Pop, Jazz und Klassik',
      b2b: 'Dienstleistungen verschiedenster Art'
    },
    icons: {
      title: ['Musik', 'Leidenschaft', 'Vertrauen', 'Nachhaltigkeit'],
      text: [
        'Musik ist unsere Leidenschaft. Kultur verstehen wir als Innovationstreiber, Vordenker und Poesie. Dies charakterisiert auch unser Portfolio: Künstler, die in ihren Gebieten alternative Wege beschreiten und Klangerlebnisse schaffen.',
        'Wir geben alles, um das bestmögliche Ergebnis für unsere Kunden und Künstler zu erzielen. Wir stehen zu 100% hinter jedem Projekt und geben somit Alles für die Ermöglichung von kulturellen und musikalischen Erlebnissen.',
        'Vertrauen steht bei uns an erster Stelle. So ist uns ein kooperatives, gemeinsames Wirken sehr wichtig. Für einen kollektiven Erfolg, legen wir Wert auf gute Kommunikation und ein transparentes, lösungsorientiertes Vorgehen.',
        'Nachhaltigkeit ist ein etwas inflationär benutzter Begriff. Uns ist es allerdings ernst, langfristig eine Karriere mit unseren Künstlern und Partnern aufzubauen, Rücksicht auf unsere Muttererde zu nehmen und faire Konditionen zu vertreten.'
      ]
    }
  },
  contact: {
    title: 'Kontakt',
    location: 'Overath, Deutschland',
    phoneNumber: '+49 176 / 22277321',
    email: 'info(at)soundsgoodagency.com',
    manager: 'Geschäftsführer'
  },
  footer: {
    facebook: 'Folge uns auf Facebook'
  },
  error: 'Ein Fehler ist aufgetreten. Bitte aktualisieren Sie die Seite oder versuchen Sie es später erneut.',
  references: 'Referenzen',
  requestOrQuestions: '(An-)Fragen',
  bandcoaching: 'Bandcoaching',
  moreInfo: 'Mehr Informationen',
  impressum: {
    title: 'Impressum'
  },
  login: {
    emptyField: 'Please enter a username and password.',
    invalid: 'You\'ve entered an invalid combination of username and password.',
  }
}

let lang = document.cookie.substr(document.cookie.indexOf('lang=') + 5, 2);
export const getLang = () => lang;
export const setLang = l => {
  lang = l;
  const d = new Date();
  d.setTime(d.getTime() + (700*24*60*60*1000));
  document.cookie = `lang=${lang};expires=${d.toUTCString()};path=/`;
}

if(lang !== 'en' && lang !== 'de') {
  setLang((navigator.language || navigator.userLanguage).includes('de') ? 'de' : 'en');
}

export default (key, placeHolders) => {
  let s = key.split('.').reduce((acc, val) => acc[val], lang === 'en' ? en : de);
  for(const [key, value] of Object.entries(placeHolders || {})) {
    s = s.replace(`{{${key}}}`, value);
  }
  return s;
}

const desc = {
  aritist: {
    en: "We represent national and international artists in the fields of Pop, Jazz or Classical...",
    de: "Wir vertreten nationale und internationale Künstler aus den Bereichen Pop, Jazz und Klassik."
  },
  consultancy: {
    en: "Consulting specialised in the cultural field, helping to solve problems with scientific, and strategic methods...",
    de: "Beratung die auf den Kultursektor spezialisiert ist und durch strategische, sowie wissenschaftliche Methoden und Kreativität..."
  },
  b2b: {
    en: "We help and take over tasks in the cultural field as a reliable partner...",
    de: "Wir übernehmen und helfen bei den verschiedensten Aufgaben, als verlässlicher Partner im Kulturbereich."
  },
  main: {
    en: "Soundsgood is a Music Agency specialised in Artist-Booking, Cultural Consulting and B2B-services. Creative and innovative concepts and artists...",
    de: "Soundsgood ist eine Musikagentur, die in den Bereichen Künstler-Booking, Cultural Consulting und B2B Dienstleistungen tätig ist. Kreative und innovative Konzepte und Künstler..."
  }
}

export const MetaDesc = () => {
  const name = window.location.href.split('/').pop();
  const { en, de } = desc[name] || desc.main;
  return (
    <Helmet>
      <meta name="description" lang="en" content={en} />
      <meta name="description" lang="de" content={de} />
    </Helmet>
  )
}
