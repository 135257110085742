import { Dashboard as DashboardLayout } from 'layouts';
import Notify from 'components/Notify';
import { withStyles, Button, Dialog, DialogContent, InputLabel, FormControl, Select, DialogTitle, MenuItem, DialogActions, Input } from '@material-ui/core';
import InputCard from 'components/InputCard';
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { request } from 'utils/';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
})

class Pages extends Component {
  signal = false;
  state = { pages: null, loading: true, page: 'b2b', lang: 'en' , open: true }

  save = async ({ content }) => {
    try {
      this.setState({ loading: true });
      await request(`/pages/`, 'PATCH', { [this.state.lang]: content, name: this.state.page });
      if(this.signal)
        this.setState({ success: true, error: false, loading: false, pages: this.state.pages.map(p => p.name === this.state.page ? ({ ...p, [this.state.lang]: content }): p) });
    } catch(e) {
      console.log(e) // eslint-disable-line no-console
      if(this.signal)
        this.setState({ success: false, loading: false, error: true });
    }
  }

  componentWillMount() {
    this.signal = true;
    request('/pages')
      .then(pages => {
        if(this.signal) {
          this.setState({ pages, loading: false })
        }
      })
      .catch(console.error) // eslint-disable-line no-console
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  render() {
    const { loading, pages } = this.state;
    const { classes } = this.props;

    if(loading) return (
      <DashboardLayout title="Pages">
        <div className={classes.root}>
          {Notify.showLoading()}
        </div>
      </DashboardLayout>
    );
    
    const page = pages.find(p => p.name === this.state.page);
    const fields = [{
      label: 'Content',
      key: 'content',
      value: page[this.state.lang],
      required: true,
      multiline: true,
      rows: 50
    }]

    return (
      <DashboardLayout title="Pages">
        {Notify.show(this)}
        <div className={classes.root}>
          <Button onClick={() => this.setState({ open: true })}>Select a Page</Button>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            onClose={this.handleClose}
            open={this.state.open}
          >
            <DialogTitle>Select a Page</DialogTitle>
            <DialogContent>
              <form className={classes.container}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-native-simple">Page</InputLabel>
                  <Select
                    input={<Input id="age-native-simple" />}
                    native
                    onChange={this.handleChange('page')}
                    value={this.state.page}
                  >
                    {pages.map(p => (
                      <option
                        key={p.id}
                        value={p.name}
                      >
                        {p.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-simple">Lang</InputLabel>
                  <Select
                    input={<Input id="age-simple" />}
                    onChange={this.handleChange('lang')}
                    value={this.state.lang}
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="de">German</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => this.setState({ open: false })}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              xs={12}
            >
              <InputCard
                fields={fields}
                save={this.save}
                title="Page Description"
              />
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Pages);
