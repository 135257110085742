import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Avatar, Typography, Button } from '@material-ui/core';
import { Portlet, PortletContent } from 'components';
import styles from './styles';

class AccountProfile extends Component {
  render() {
    const { classes, className, user, uploadPicture } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography
                dangerouslySetInnerHTML={{ __html: user.display_name }}
                variant="h2"
              />
              <br /><br />
              <input
                hidden
                id="fileid"
                name="filename"
                onChange={e => uploadPicture(e.target.files)}
                type="file"
              />
              <Button
                className={classes.uploadButton}
                color="primary"
                onClick={() => document.getElementById('fileid').click()}
                variant="text"
              >
                Upload picture
              </Button>
            </div>
            <Avatar
              className={classes.avatar}
              src={`${process.env.REACT_APP_BASE_URL}/static/musicians/${user.id}.jpg`}
            />
          </div>
        </PortletContent>
      </Portlet>
    );
  }
}

AccountProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  uploadPicture: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountProfile);
