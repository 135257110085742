import NavBar from 'components/Main/NavBar';
import Footer from 'components/Main/Footer';
import settings from 'utils/settings';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import __, { getLang, setLang, MetaDesc } from 'utils/lang';
import './styles.css';
import CookieBanner from 'components/Main/CookieBanner';

export default class Main extends Component {
  state = {
    consultancy: false,
    artists: false,
    b2b: false,
    icons: [
      { color: 'black', pulsating: false },
      { color: 'black', pulsating: false },
      { color: 'black', pulsating: false },
      { color: 'black', pulsating: false }
    ]
  }

  iconMouseOver = i => () => {
    const icons = this.state.icons;
    icons[i].color = [settings('headers.colors.consultancy'), settings('headers.colors.b2b'), settings('headers.colors.artists'), 'green'][i];
    icons[i].pulsating = true;
    this.setState({ icons });
  }

  iconMouseOut = i => () => {
    const icons = this.state.icons;
    icons[i].pulsating = false;
    this.setState({ icons });
  }

  render() {
    return (
      <>
        {MetaDesc()}
        <CookieBanner />
        <header className="w3-center">
          <br />
          <Link to="/">
            <img src={`${process.env.REACT_APP_BASE_URL}/static/logo.png`} style={{ height: '150px' }} className="w3-animate-zoom" alt="Soundsgood" />
          </Link>
          <br /><br />
          <div style= {{ fontFamily: 'sans-serif' }}>
            <NavBar />
            <button onClick={() => { setLang(getLang() === 'en' ? 'de' : 'en'); this.forceUpdate() }} className="w3-bar-item w3-button"><i className="fas fa-globe" /> {getLang() === 'en' ? 'DE' : 'EN'}</button>
          </div>
        </header>

        <div className="w3-row">
          <br />
          {['consultancy', 'artists', 'b2b'].map((page, key) => (
            <div className= "w3-third" key={key} onMouseOver={() => this.setState({ [page]: true })} onMouseOut={() => this.setState({ [page]: false })}>
              <Link to={`/${page}`}>
                <div className="w3-card" style={{ cursor: 'pointer' }}>
                  <div className= "w3-display-container" style={{ height: '100%', width: '100%' }}>
                    <div style={{ width: '100%', backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/static/headers/${page}.jpg)`, height: '250px', backgroundPosition: 'center', backgroundSize: 'cover'}} />
                    <div className="w3-display-middle w3-center w3-opacity" style={{ minWidth: '100%', height: '70px', backgroundColor: settings(`headers.colors.${page}`)}}/>
                    <div className="w3-display-middle" style={{ color: 'white' }} id="consT"><h1><b style= {{ fontFamily: 'sans-serif' }}>{__(`navbar.${page}`).toUpperCase()}</b></h1></div>
                    {this.state[page] && (
                      <div className="w3-display-bottommiddle w3-center w3-large" style={{ width: '100%', color: 'white', backgroundColor: settings(`headers.colors.${page}`), opacity: 0.8}}>
                        {__(`main.subtext.${page}`)}
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>

        <div className="w3-container" style={{ padding: '40px 16 px' }} id="about">
          <br />
          <h3 className="w3-center w3-animate-bottom" style= {{ fontFamily: 'sans-serif' }}>{__('main.subtitle')}</h3>
          <div className="w3-row-padding w3-center w3-animate-bottom" style={{ marginTop: '64px'}}>
            {[0, 1, 2, 3].map(i => (
              <div className="w3-quarter" onMouseOver={this.iconMouseOver(i)} onMouseOut={this.iconMouseOut(i)} key={i}>
                <i className={`${settings(`main.icons.${i}`)} w3-margin-bottom w3-jumbo w3-center${this.state.icons[i].pulsating ? ' animatedMainIcons': ''}`} style={{ color: this.state.icons[i].color }} />
                <p className="w3-large" style= {{ fontFamily: 'sans-serif' }}>{__(`main.icons.title.${i}`)}</p>
                <p style= {{ fontFamily: 'sans-serif' }}>{__(`main.icons.text.${i}`)}</p>
              </div>
            ))}
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </>
    )
  }
}
