import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Card from '../Card';
import styles from './styles';
import Notify from '../Notify';
import { request } from 'utils/';

class CardOverview extends Component {
  signal = false;

  state = {
    cards: [],
    loading: true,
    success: false,
    error: false
  };

  removeFile = card => {
    this.setState({ loading: true });
    request(card.delete)
      .then(() => {
        if(this.signal)
          this.setState({ loading: false, error: false, success: true, cards: this.state.cards.filter(f => f.title !== card.title) });
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
        if(this.signal)
          this.setState({ loading: false, error: true, success: false });
      })
  }

  componentWillMount() {
    this.signal = true;
    this.props.fetchData()
      .then(cards => {
        this.setState({ cards, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false, error: true });
      })
  }

  componentWillUnmount() {
    this.signal = false;
  }

  render() {
    const  { classes } = this.props;
    const { cards, loading } = this.state;
    if(loading) return Notify.showLoading();
    if(!cards.length) {
      return <Typography> This folder is empty. </Typography>
    }
    return (
      <div className={classes.content}>
        {Notify.show(this)}
        <Grid
          container
          spacing={3}
        >
          {cards.map((card, i) => (
            <Grid
              item
              key={`Card${i}`}
              lg={2}
              md={3}
              xs={6}
            >
              <Card
                data={card}
                removeFile={() => this.removeFile(card)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

CardOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchData: PropTypes.func.isRequired
}

export default withStyles(styles)(CardOverview);
