import React from 'react';
import PropTypes from 'prop-types';

export default function Textify({ text }) {
  return (
    text.split(/\r?\n/).map((l, key) => {
      if(l.startsWith('###')) {
        return <b key={key}>{l.slice(3)}<br /></b>
      }
      if(l.startsWith('##')) {
        return <h4 key={key}>{l.slice(2)}<br /></h4>
      }
      if(l.startsWith('#')) {
        return <h3 key={key}>{l.slice(1)}<br /></h3>
      }
      return <p key={key}>{l}<br /></p>
    })
  )
}

Textify.propTypes = {
  text: PropTypes.string.isRequired
}
