import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';
import __ from '../../utils/lang';
import React from 'react';

export default function B2B() {
  const style = {
    maxWidth: '150px',
    margin: '5px'
  }

  return (
    <>
      <div className="w3-center" style={{ display: 'inline', margin: 'auto' }}>
        <Link to="/contact" className="w3-bar-item w3-button w3-animate-left" style={{ width: '45%' }}><i className="fa fa-envelope" />&nbsp;&nbsp;{__('requestOrQuestions')}</Link>
      </div>
      <br /><br />
      <h1><b>{__('references')}</b></h1>
      <br /><br />
      <AliceCarousel
        autoPlayInterval={2000}
        autoPlay
        mouseDragEnabled
        buttonsDisabled
        dotsDisabled
        fadeOutAnimation
        responsive={{
          0: { items: 3 }
        }}
      >

        <a onDragStart={e => e.preventDefault()} href="https://www.oberlinger-communication.de" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/OC.png`} style={style} alt="Oberlinger Communication" /></a>
        <a onDragStart={e => e.preventDefault()} href="http://www.classicalbeat.de/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/CB.png`} style={style} alt="Classical Beat" /></a>
        <a onDragStart={e => e.preventDefault()} href="https://karlskonzerte.wixsite.com/karlskonzerte/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/KarlsKonzerte.png`} style={style} alt="Karlskonzerte" /></a>
        <a onDragStart={e => e.preventDefault()} href="http://www.kuerbiz.biz" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/Kurbiz.png`} style={style} alt="Kuerbiz" /></a>
        <a onDragStart={e => e.preventDefault()} href="https://www.lahr.de/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/StadtLahr.png`} style={style} alt="Lahr" /></a>
        <a onDragStart={e => e.preventDefault()} href="http://www.tatunddrang.de/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/TatundDrang.jpg`} style={style} alt="Tatuddrang" /></a>
      </AliceCarousel>
      <br />
    </>
  )
}
