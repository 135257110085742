import Header from 'components/Main/Header';
import Footer from 'components/Main/Footer';
import React, { useState } from 'react';
import __ from 'utils/lang';

function useForceUpdate(){
  const [value, set] = useState(true);
  return () => set(!value);
}

export default function Contact() {
  const forceUpdate = useForceUpdate();

  return (
    <>
      <Header name="contact" forceUpdate={forceUpdate}/>
        <div className="w3-container" style={{ padding: '20px 16px' }}>
          <h3 className="w3-center">{__('contact.title')}</h3>
          <div className="w3-center" style={{ margin: 'auto' }}>
            <table style={{ border: '0px', margin: 'auto', textAlign: 'left' }} className="w3-hide-small">
              <tbody>
                <tr>
                  <td rowSpan={3}>
                    <div style={{ whiteSpace: 'nowrap' }}>
                      <p><i className="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right" />{__('contact.location')}</p>
                      <p><i className="fa fa-phone fa-fw w3-xxlarge w3-margin-right" />{__('contact.phoneNumber')}</p>
                      <p><a href="mailto:info@soundsgoodagency.com"><i className="fa fa-envelope fa-fw w3-xxlarge w3-margin-right" />{__('contact.email')}</a></p>
                      <a href="https://www.facebook.com/Soundsgoodagency/"><p><i className="fab fa-facebook w3-hover-opacity fa-fw w3-xxlarge w3-margin-right" />{__('footer.facebook')}</p></a>
                    </div>
                  </td>
                  <td rowSpan={3}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>{__('contact.manager')}</b><br />
                    Ruben Bauer<br />
                    <a href="mailto:ruben@soundsgoodagency.com">ruben(at)soundsgoodagency.com</a>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>Graphic Designer</b><br />
                    Mayra Slagboom<br />
                    <a href="mailto:mayraslagboom@gmail.com">mayraslagboom(at)gmail.com</a>
                  </td>
                </tr>
                <tr>
                  <td><br /><br /></td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>{__('contact.manager')}</b><br />
                    Friedemann Bauknecht<br />
                    <a href="mailto:friedemann@soundsgoodagency.com">friedemann(at)soundsgoodagency.com</a>
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </td>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>Web &amp; NodeJS Developer</b><br />
                    Dennis Vriend<br />
                    <a href="mailto:dennisvriend@dennisvriend.nl">dennisvriend(at)dennisvriend.nl</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="w3-hide-large w3-hide-medium">
            <div style={{ whiteSpace: 'nowrap'}}>
              <p><i className="fa fa-map-marker fa-fw w3-xxlarge w3-margin-right" />{__('contact.location')}</p>
              <p><i className="fa fa-phone fa-fw w3-xxlarge w3-margin-right" />{__('contact.phoneNumber')}</p>
              <p><a href="mailto:info@soundsgoodagency.com"><i className="fa fa-envelope fa-fw w3-xxlarge w3-margin-right" />info(at)soundsgoodagency.com</a></p>
              <a href="https://www.facebook.com/Soundsgoodagency/"><p><i className="fab fa-facebook w3-hover-opacity fa-fw w3-xxlarge w3-margin-right" />{__('footer.facebook')}</p></a>
            </div>
            <br />
            <table syle="border:0px">
              <tbody>
                <tr>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>{__('contact.manager')}</b><br />
                    Ruben Bauer<br />
                    <a href="mailto:ruben@soundsgoodagency.com">ruben(at)soundsgoodagency.com</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table syle="border:0px">
              <tbody>
                <tr>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>{__('contact.manager')}</b><br />
                    Friedemann Bauknecht<br />
                    <a href="mailto:friedemann@soundsgoodagency.com">friedemann(at)soundsgoodagency.com</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br /><br /><br />
            <table syle="border:0px">
              <tbody>
                <tr>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>Graphic Designer</b><br />
                    Mayra Slagboom<br />
                    <a href="mailto:mayraslagboom@gmail.com">mayraslagboom(at)gmail.com</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table syle="border:0px">
              <tbody>
                <tr>
                  <td>
                    <i className="fas fa-address-card fa-fw w3-xxlarge w3-margin-right" />
                  </td>
                  <td>
                    <b>Web &amp; NodeJS Developer</b><br />
                    Dennis Vriend<br />
                    <a href="mailto:dennisvriend@dennisvriend.nl">dennisvriend(at)dennisvriend.nl</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
          </div>
        </div>
      <Footer />
    </>
  )
}
