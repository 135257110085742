import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
})

class NotifyUnstyled extends Component {
  render() {
    let { classes, variant, message, onClose } = this.props;
    if(variant === 'error' && typeof message !== 'string') {
      message = 'Unfortunately, an error has occured.';
    }
    if(variant === 'success' && typeof message !== 'string') {
      message = 'Successfully performed action.';
    }
    
    const Icon = variantIcon[variant];

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={5000}
        onClose={(_, reason) => reason !== 'clickaway' && onClose()}
        open
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          className={classes[variant]}
          message={
            <span
              className={classes.message}
              id="client-snackbar"
            >
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {message}
            </span>
          }
        />
      </Snackbar>
    );
  }
}

const Notify = withStyles(styles)(NotifyUnstyled);

// eslint-disable-next-line
Notify.show = component => {
  return(
    <>
      {component.state.error &&
        <Notify
          message={component.state.error}
          onClose={() => component.setState({ error: false })}
          variant="error"
        />
      }
      {component.state.success &&
        <Notify
          message={component.state.success}
          onClose={() => component.setState({ success: false })}
          variant="success"
        />
      }
    </>
  )
}

// eslint-disable-next-line
Notify.showLoading = () => {
  return (
    <div
      style={{
        paddingTop: '48px',
        paddingBottom: '24px',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </div>
  )
}

NotifyUnstyled.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

export default Notify;
