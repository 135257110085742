import React, { Component } from 'react';
import CardOverview from 'components/CardOverview';
import { request } from 'utils/';

export default class ArtistOverview extends Component {
  render() {
    return (
      <CardOverview
        fetchData={() => request('/artists')
          .then(artists => artists.map(a => ({
            title: a.username,
            image: `${process.env.REACT_APP_BASE_URL}/static/musicians/${a.id}.jpg`,
            link: a.id
          })))
        }
      />
    );
  }
}
