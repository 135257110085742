import 'react-alice-carousel/lib/alice-carousel.css';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';
import __ from 'utils/lang';
import React from 'react';

export default function Consultancy() {
  const style = {
    maxWidth: '150px',
    margin: '5px'
  }

  return (
    <>
      <div className="w3-center" style={{ display: 'inline', margin: 'auto' }}>
        <Link to="/contact" className="w3-bar-item w3-button w3-animate-left" style={{ width: '45%' }}><i className="fa fa-envelope" />&nbsp;&nbsp;{__('requestOrQuestions')}</Link>
      </div>
      <div className="w3-center" style={{ display: 'inline', margin: 'auto' }}>
        <a href={`${process.env.REACT_APP_BASE_URL}/static/Bandcoaching_Infosheet_Soundsgood.pdf`}  target="_blank" rel="noopener noreferrer" className="w3-bar-item w3-button w3-animate-left" style={{ width: '45%' }}><i className="fas fa-download" />&nbsp;&nbsp;{__('bandcoaching')}</a>
      </div>
      <br /><br />
      <h1><b>{__('references')}</b></h1>
      <br /><br />
      <AliceCarousel
        autoPlayInterval={2000}
        autoPlay
        mouseDragEnabled
        buttonsDisabled
        dotsDisabled
        fadeOutAnimation
        responsive={{
          0: { items: 3 }
        }}
      >
        <a onDragStart={e => e.preventDefault()} href="http://www.classicalbeat.de/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/CB.png`} style={style} alt="Classical Beat" /></a>
        <a onDragStart={e => e.preventDefault()} href="http://www.schwarzwald-musikfestival.de/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/SMF.png`} style={style} alt="Schwarzwald MusikFestival" /></a>
        <a onDragStart={e => e.preventDefault()} href="https://www.nacona.de/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/NACONA.png`} style={style} alt="Nacona" /></a>
        <a onDragStart={e => e.preventDefault()} href="https://www.beyond-festival.com/" target="_blank" rel="noopener noreferrer"><img src={`${process.env.REACT_APP_BASE_URL}/static/references/BEYOND.jpg`} style={style} alt="Beyond Festival" /></a>
      </AliceCarousel>
      <br />
    </>
  )
}
