import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { UsersTable } from './components';
import styles from './style';
import { request } from 'utils/';
import Notify from 'components/Notify';

class ArtistsList extends Component {
  signal = true;

  state = {
    users: [],
    loading: false,
    success: false,
    error: null
  };

  componentDidMount() {
    this.signal = true;
    request('/artists')
      .then(users => {
        if(this.signal) {
          this.setState({ users, loading: false })
        }
      })
      .catch(console.error) // eslint-disable-line no-console
  }

  componentWillUnmount() {
    this.signal = false;
  }

  addArtist = async name => {
    try {
      this.setState({ loading: true });
      const { id } = await request('/artists', 'POST', { name });
      this.setState({ loading: false, success: true, error: false, users: [ ...this.state.users, { name, id }] });
    } catch(e) {
      this.setState({ loading: false, success: false, error: true });
    }
  }

  removeUser = async (id, name, force = false) => {
    if(!force) {
      return this.setState({ confirmation: { id, name }});
    }
    try {
      this.setState({ loading: true, confirmation: null });
      await request(`/artists/${id}`, 'DELETE');
      this.setState({ loading: false, success: true, error: false, users: this.state.users.filter(u => u.id !== id) });
    } catch(e) {
      this.setState({ loading: false, success: false, error: true });
    }
  }

  render() {
    const { classes } = this.props;
    if(this.state.loading) return (
      <DashboardLayout title="Users">
        <div className={classes.root}>
          {Notify.showLoading()}
        </div>
      </DashboardLayout>
    );

    return (
      <DashboardLayout title="Users">
        <div className={classes.root}>
          {Notify.show(this)}
          <TextField
            className={this.props.classes.textField}
            id="addArtist"
            label="Enter a new username"
            margin="dense"
            onKeyDown={e => e.key === 'Enter' && this.addArtist(document.getElementById('addArtist').value)}
            variant="outlined"
          />
          <Button
            className={classes.addArtistButton}
            color="primary"
            onClick={() => this.addArtist(document.getElementById('addArtist').value)}
            variant="text"
          >
            Add new Artist
          </Button>
          <div className={classes.content}>
            <UsersTable
              removeUser={this.removeUser}
              users={this.state.users}
            />
          </div>
        </div>
        {this.state.confirmation && (
          <Dialog
            aria-labelledby="confirmation"
            onClose={() => this.setState({ confirmation: null })}
            open
          >
            <DialogTitle id="confirmation">
              Warning
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{`Are you sure you'd like to delete ${this.state.confirmation.name}`}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => this.setState({ confirmation: null })}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => { this.removeUser(this.state.confirmation.id, this.state.confirmation.name, true) }}
              >
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </DashboardLayout>
    );
  }
}

ArtistsList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ArtistsList);
