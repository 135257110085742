import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import __ from 'utils/lang';

export default class Landing extends Component {
  render() {
    return (
      <>
        <footer className="w3-row w3-black w3-padding-32 w3-hide-small">
          <div className="w3-third">
            <div className="w3-right">
              <Link to="/consultancy" className="w3-hover-opacity" style={{ textDecoration: 'none' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-hands-helping fa-fw w3-large w3-margin-right"/><u>{__('navbar.consultancy')}</u></p></Link>
              <Link to="/contact" className="w3-hover-opacity" style={{ textDecoration: 'none' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-envelope fa-fw w3-large w3-margin-right"/><u>{__('navbar.contact')}</u></p></Link>
              <p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-map-marker fa-fw w3-large w3-margin-right"/>{__('contact.location')}</p>
              <p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-phone fa-fw w3-large w3-margin-right"/>{__('contact.phoneNumber')}</p>
            </div>
          </div>
          
          <div className="w3-center w3-third">
            <Link to="/artists" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-address-book fa-fw w3-large w3-margin-right"/><u>{__('navbar.artists')}</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></Link>
            <Link to="/sustainability" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-tree fa-fw w3-large w3-margin-right"/><u>{__('navbar.sustainability')}</u></p></Link>
            <Link to="/impressum" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-gavel fa-fw w3-large w3-margin-right"/><u>{__('navbar.impressum')}</u></p></Link>
            <Link to="/login" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-lock fa-fw w3-large w3-margin-right"/><u>{__('navbar.login')}</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p></Link>
          </div>
          
          <div className="w3-third">
            <div className="w3-left">
              <Link to="/b2b" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-people-carry fa-fw w3-large w3-margin-right text-decoration:none;" /><u>B2B</u></p></Link>
              <Link to="/b2b" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }}><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa- fa-users w3-large w3-margin-right text-decoration:none;" /><u>{__('navbar.about')}</u></p></Link>
              <p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><a href="mailto:info@soundsgoodagency.com" className="w3-hover-opacity" style={{ textDecoration: 'none' }}><i className="fa fa-envelope fa-fw w3-large w3-margin-right"/><u>{__('contact.email')}</u></a></p>
              <a href="https://www.facebook.com/Soundsgoodagency/" className="w3-hover-opacity" style={{ textDecoration: 'none', marginBottom: '10px' }} rel="noopener noreferrer" target="_blank"><p style= {{ fontFamily: 'sans-serif' }}><i className="fab fa-facebook fa-fw w3-large w3-margin-right"/><u>{__('footer.facebook')}</u></p></a>
            </div>
          </div>
        </footer>

        <footer className="w3-row w3-black w3-padding-64 w3-hide-large w3-hide-medium w3-small">
          <div className="w3-center">
            <Link to="/sustainability"><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-tree w3-hover-opacity fa-fw w3-large"/>{__('navbar.sustainability')} </p></Link>
            <Link to="/contact"><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fas fa-gavel w3-hover-opacity fa-fw w3-large"/>{__('navbar.impressum')} </p></Link>
            <Link to="/login"><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-lock w3-hover-opacity fa-fw w3-large"/>{__('navbar.login')}</p></Link>
            <p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-map-marker fa-fw w3-large"/>{__('contact.location')}</p>
            <p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fa fa-phone fa-fw w3-large"/>{__('contact.phoneNumber')}</p>
            <p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><a href="mailto:info@soundsgoodagency.com"><i className="fa fa-envelope fa-fw w3-large"/>{__('contact.email')}</a></p>
            <a href="https://www.facebook.com/Soundsgoodagency/" rel="noopener noreferrer" target="_blank"><p style= {{ fontFamily: 'sans-serif', marginBottom: '10px' }}><i className="fab fa-facebook w3-hover-opacity fa-fw w3-large"/>{__('footer.facebook')}</p></a>
          </div>
        </footer>
      </>
    )
  }
}
