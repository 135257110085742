import PropTypes from 'prop-types';
import __, { getLang } from '../../utils/lang';
import React from 'react';

export default function ArtistPage({ artist }) {
  const [ author, ...quoteParts ] = artist[`quote_${getLang()}`].split(']');
  const quote = quoteParts.join('/');

  return (
    <div className="w3-container" style={{ width: '100%', maxWidth: '1150px', margin: 'auto' }}>
      <div className="w3-container" style={{ margin: 'auto', marginTop: '20px' }}>
        <div className="w3-card-4" style={{ width: '100%' }}>
          <div className="w3-row">
            <div className="w3-third w3-padding" style={{ marginTop: '10px' }}>
              <img src={`${process.env.REACT_APP_BASE_URL}/static/musicians/${artist.id}.jpg`} style={{ width: '100%' }} alt={artist.username} />
            </div>
            <div className="w3-twothird w3-padding">
              <div  style={{ marginRight: '28px' }} dangerouslySetInnerHTML={{ __html: artist[`info_${getLang()}`] }} />
            </div>
          </div>
        </div>
        <br />
        <div className="w3-card-4 w3-padding" style={{ width: '100%' }}>
          <p className="w3-center w3-large">Youtube</p>
          <div style={{ width: '100%', height: '300px' }}><iframe title="Youtube Preview" src={`https://www.youtube.com/embed/${artist.youtube.split('/').pop()}`} style={{ width: '100%', height: '100%' }} /></div>
        </div>
        <br />
        <div className="w3-card-4" style={{ width: '100%' }}>
          <div className="w3-row">
            <div className="w3-half">
              <div className="w3-container">
                <div style={{ marginRight: '15px' }}>
                  <div className="w3-panel w3-leftbar">
                    <p>
                      <i className="fa fa-quote-right w3-large" />
                      <br />
                      <i className="w3-large">{quote}</i>
                    </p>
                    <p>{author}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w3-half w3-padding w3-center">
              <p style={{ marginBottom: '10px' }} className="w3-center w3-large">{__('moreInfo')}</p>
              {artist.contact === 'ruben' ? (
                <p style={{ marginBottom: '10px' }}><a className="w3-button" style={{ width: '200px', textAlign: 'left', margin: '0px', padding: '0px' }} href="mailto:ruben@soundsgoodagency.com"><i className="fa fa-envelope fa-fw w3-xlarge w3-margin-right" /><u>Ruben Bauer</u></a></p>
              ) : (
                <p style={{ marginBottom: '10px' }}><a className="w3-button" style={{ width: '200px', textAlign: 'left', margin: '0px', padding: '0px' }} href="mailto:friedemann@soundsgoodagency.com"><i className="fa fa-envelope fa-fw w3-xlarge w3-margin-right" /><u>Friedemann Bauknecht</u></a></p>
              )}
              {[['website', 'fas fa-desktop'], ['youtube', 'fab fa-youtube'], ['facebook', 'fab fa-facebook-square'], ['instagram', 'fab fa-instagram'], ['spotify', 'fab fa-spotify'], ['linkedin', 'fab fa-linkedin-square']].filter(x => artist[x[0]]).map(([name, icon], key) => (
                <p style={{ marginBottom: '10px' }} key={key}><a href={artist[name]} className="w3-button" style={{ width: '200px', textAlign: 'left', margin: '0px', padding: '0px' }} target="_blank" rel="noopener noreferrer"><i className={`${icon} fa-fw w3-xlarge w3-margin-right`} /> {name.charAt(0).toUpperCase() + name.slice(1)}</a></p>
              ))}
              {artist.photo_copyright && <p style={{ marginBottom: '10px' }}><button className="w3-button" style={{ width: '200px', textAlign: 'left', margin: '0px', padding: '0px' }}><i className="fas fa-copyright fa-fw w3-xlarge w3-margin-right" /> {`Photo: ${artist.photo_copyright}`}</button></p>}
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  )
}

ArtistPage.propTypes = {
  artist: PropTypes.object.isRequired
}
