import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Landing from './views/Landing';
import Main from './views/Main';
import Artists from './views/Artists';
import Page from './views/Page';
import Contact from './views/Contact';
import Login from './views/Login';

import Downloads from './views/Admin/Downloads';
import ArtistsList from './views/Admin/ArtistsList';
import Artist from './views/Admin/Artist';
import Pages from './views/Admin/Pages';
import Account from 'views/Admin/Account';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          component={Landing}
          exact
          path="/"
        />
        <Route
          component={Main}
          exact
          path="/main"
        />
        <Route
          component={Artists}
          exact
          path="/artists"
        />
        <Route
          component={Artists}
          exact
          path="/artists/:id/:name"
        />
        {['consultancy', 'b2b', 'festival', 'sustainability', 'about', 'impressum'].map((page, key) => (
          <Route
            component={Page}
            exact
            key={key}
            path={`/${page}`}
          />
        ))}
        <Route
          component={Contact}
          exact
          path="/contact"
        />
        <Route
          component={Login}
          exact
          path="/login"
        />
        
        <Redirect
          exact
          from="/admin"
          to="/admin/downloads"
        />
        <Route
          component={Downloads}
          path="/admin/downloads"
        />
        <Route
          component={ArtistsList}
          exact
          path="/admin/artists"
        />
        <Route
          component={Artist}
          exact
          path="/admin/artists/:id"
        />
        <Route
          component={Pages}
          exact
          path="/admin/pages"
        />
        <Route
          component={Account}
          exact
          path="/admin/account"
        />
        <Redirect to="/" />
      </Switch>
    );
  }
}
