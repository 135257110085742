import React, { Component } from 'react';
import { getLang } from "utils/lang";

let accepted = document.cookie.includes('gdpr=2');

export default class CookieBanner extends Component {
  disableCookies = () => {
    const d = new Date();
    d.setTime(d.getTime() + (700*24*60*60*1000));
    document.cookie = `gdpr=2;expires=${d.toUTCString()};path=/`;
    accepted = true;
    this.forceUpdate();
  }

  render() {
    if(accepted) return <></>;

    const [ a, b ] = ({
      en: [
        "This website uses cookies. By continuing to use the website, you agree to the use of cookies.",
        "Click here for more information."
      ],
      de: [
        "Diese Website verwendet Cookies. Mit der weiteren Nutzung der Website stimmen Sie der Verwendung von Cookies zu.",
        "Klicken Sie hier für weitere Informationen."
      ]
    })[getLang()];

    return (
      <div className="cookieBanner">
        <h3 style={{ marginBottom: '0px', fontFamily: 'sans-serif' }}>Cookies</h3>
        <p style={{ marginTop: '0px', fontFamily: 'sans-serif'}}>
          {a}
          <a href="https://www.fourartists.com/datenschutz/index.html" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>{b}</a>
          <span onClick={this.disableCookies} class="w3-button w3-display-topright w3-xlarge">&times;</span>
        </p>
      </div>
    )
  }
}
