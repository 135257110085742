import React from 'react';

export default function Consultancy() {
  return (
    <>
      <div className="w3-center" style={{ display: 'inline', margin: 'auto' }}>
        <a href={`${process.env.REACT_APP_BASE_URL}/static/Sustainability_Plan.pdf`}  target="_blank" rel="noopener noreferrer" className="w3-bar-item w3-button w3-animate-left" style={{ width: '45%' }}><i className="fas fa-download" />&nbsp;&nbsp;Sustainability Plan</a>
      </div>
      <div className="w3-center" style={{ display: 'inline', margin: 'auto' }}>
        <a href={`${process.env.REACT_APP_BASE_URL}/static/Sustainability_Policy.pdf`}  target="_blank" rel="noopener noreferrer" className="w3-bar-item w3-button w3-animate-left" style={{ width: '45%' }}><i className="fas fa-download" />&nbsp;&nbsp;Sustainability Policy</a>
      </div>
    </>
  )
}
