import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography, Divider } from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  GetApp as GetAppIcon,
  RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { Paper } from 'components';
import styles from './styles';
import { Link } from 'react-router-dom';

class Card extends Component {
  render() {
    const { classes, className, data, removeFile } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper className={rootClassName}>
        <div className={classes.imageWrapper}>
          {data.link ? (
            <Link to={`/admin/downloads/${data.link}`}>
              <img
                alt={data.title}
                className={classes.image}
                src={data.image}
              />
            </Link>
          ) : (
            <img
              alt={data.title}
              className={classes.image}
              src={data.image}
            />
          )}
        </div>
        <div className={classes.details}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            {data.title}
          </Typography>
        </div>
        {data.download && <>
          <Divider />
          <div className={classes.stats}>
            <AccessTimeIcon className={classes.updateIcon} />
            <Typography
              className={classes.updateText}
              variant="body2"
            >
              {data.date}
            </Typography>
            { removeFile && (
              <RemoveCircleIcon
                className={classes.downloadsIcon}
                onClick={removeFile}
              />
            )}
            <GetAppIcon
              className={classes.downloadsIcon}
              onClick={() => { window.location.href = data.download }}
            />
            <Typography
              className={classes.downloadsText}
              onClick={() => { window.location.href = data.download }}
              variant="body2"
            >
              Download
            </Typography>
          </div>
        </>}
      </Paper>
    );
  }
}

Card.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  removeFile: PropTypes.any
}

export default withStyles(styles)(Card);