import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { MetaDesc } from 'utils/lang';
import './styles.css';

export default class Landing extends Component {
  state = {
    redirect: false
  }

  render() {
    if(this.state.redirect) {
      return (
        <Redirect
          push
          to="/main"
        />
      )
    }
    setTimeout(() => this.setState({ redirect: true}), 2000);
    return (
      <div className="w3-container full-screen" style={{ width: '100%', height: '100%' }}>
        {MetaDesc()}
        <div className= "logoLanding animateLanding" style={{ width: '100%', height: '100%' }} onClick={() => this.setState({ redirect: true })}>
          <div className="lettersLanding animateLanding" style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    )
  }
}
