const settings = {
  headers: {
    colors: {
      consultancy: '#E4CE00',
      artists: '#2388CC',
      b2b: '#E10A17',
      festival: '#2388CC',
      about: '#E10A17',
      contact: '#FFB300',
      impressum: '#FFB300'
    }
  },
  main: {
    icons: ['fa fa-music', 'fa fa-heart', 'fas fa-hands-helping', 'fas fa-tree ']
  }
}

export default (key, placeHolders) => {
  let s = key.split('.').reduce((acc, val) => acc[val], settings);
  for(const [key, value] of Object.entries(placeHolders || {})) {
    s = s.replace(`{{${key}}}`, value);
  }
  return s;
}
