const ok = res => {
  if(!res.ok) {
    throw new Error(res.statusText)
  }
  if(res.status === 204) return;
  return res.json()
}

export const FILE_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAMAAAAL34HQAAAAilBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABUmLWlAAAALXRSTlMAIDfw4AMQ3yKZae2gMpAo/uTmKQSPJ47lAo1ojB7yNe8Unhy/9bP42fT3/eNjYKFRAAABj0lEQVR42u3c205CMRRF0SqcA+IFRRRv4F1Q6P//noSnJgYS914tNc71jhk50GbiAyEwxhhjjLG9bdr37jkHqxe96xxWyYpPj1WycjyvNWt2ZN/L23uW57Vm9RwvP8j0vASseQaXgPVxrHclrGZwNbSwTk71roQ1iPGisbCC3pWwzmI8b00svSthDW/iQ7Cx5K70I9+0wcpSuwQnccMSu2QsrStljVsPS+pKWJejeOdhKV2aC0LuklynelfCur2fXAcfS+bSnUSpS80SueQsjUsRNkHv0p5EmUt6b+lcorBRuwRh89n9udHS59J8IduyRYWsL89fdobNa3frViKWJWxyvQ/OC6IAy3KdFmBZwqYAq6Z/usAqGDb/9yRWem9ZwqbER75pOYl/njWu8k0kbAgbLghYhA1hQ9hwQRA2hA1hwwVB2HASCRvChrAhbAgbwgYWYUPYEDaEDWFD2HCdEjawCBvChrDhgiBsCBvChpMI65cv9vyUwI7NnKx8g1WG5f8JlB2bBsYYY4wxtrd9A4ot7s+9GIGUAAAAAElFTkSuQmCC';
export const FOLDER_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAb1BMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAG8BLSAAAAJHRSTlMAzzjTzBE08xAzyzAr6ASjA8ZwDqjtzgoZ5zJW3PUx1nicID7Ny8o4AAAAuklEQVRo3u3XyQ6CMBCA4cF9RcB9V+z7P6M6EuNysK00Lvn/0xzKfGnCAUSI6BM1zbtVAAD0UF63bavnDzpH9kDL+rWu6vmuzh0AAAAAAACAfwcWOk/CAenwNGYmHHAWMhME2Bdr010xzGflAsng/sOx35NygQfh5X53QOKRy34P4OYOFvt9gOsdxhb7vQCJV/rUUkIBcvkzaAAAAAAAAAAAAAAAAHwhENXcWrsCfgH8CLBp+zcVInruCE9I4+t9O730AAAAAElFTkSuQmCC';

export const sendFile = (user, file) => {
  const body = new FormData();
  body.append('files', file);
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/v1.php/uploadartistpicture/${user}`, { method: 'POST', body }).then(ok);
}

export const sendFiles = (files, path) => {
  const body = new FormData();
  files.forEach(f => body.append('files[]', f));
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/v1.php/files/${path}`, { method: 'POST', body }).then(ok);
}

export function request(endpoint, method = 'GET', body) {
  const options = {
    mode: 'cors',
    method,
    headers: {}
  }
  if(typeof body === 'object') {
    options.body = JSON.stringify(body);
    options.headers['Content-Type'] = 'application/json';
  }
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/v1.php${endpoint}`, options).then(ok)
}

export function getMe() {
  return {
    id: sessionStorage.getItem('userId'),
    username: sessionStorage.getItem('userName'),
    displayName: sessionStorage.getItem('userDisplayName'),
    get url() {
      throw new Error();
    },
    get name() {
      throw new Error();
    }
  }
}

window.me = getMe;

export const ensureMe = () => sessionStorage.getItem('userId') !== null || request('/me').then(d => {
  sessionStorage.setItem('userId', d.id);
  sessionStorage.setItem('userName', d.userName);
  sessionStorage.setItem('userDisplayName', d.displayName);
}).catch(() => { window.location.href = '/login' });
