import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import styles from './styles';

class InputCard extends Component {
  save = () => {
    this.props.save(this.state);
  }

  handleChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.value });
  }

  componentDidUpdate({ fields }) {
    if(fields[0].value !== this.props.fields[0].value) {
      this.setState(this.props.fields.reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {})); // eslint-disable-line react/no-did-update-set-state
    }
  }
  
  componentWillMount() {
    const { fields } = this.props;
    this.setState(fields.reduce((acc, val) => ({ ...acc, [val.key]: val.value }), {}));
  }

  render() {
    const { classes, className, title, fields } = this.props;
    if(!this.state) return <p>Loading...</p>;
    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            subtitle="The information can be edited"
            title={title}
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
              {fields.map(f => {
                const { label, key, value, options, ...rest } = f; // eslint-disable-line no-unused-vars
                if(options) {
                  return (
                    <TextField
                      SelectProps={{ native: true }}
                      className={classes.textField}
                      key={key}
                      label={label}
                      margin="dense"
                      onChange={this.handleChange(key)}
                      select
                      value={this.state[key]}
                      variant="outlined"
                      {...rest}
                    >
                      {options.map(option => (
                        <option
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </TextField>
                  )
                }
                return (
                  <TextField
                    className={classes.textField}
                    key={key}
                    label={label}
                    margin="dense"
                    onChange={this.handleChange(key)}
                    value={this.state[key]}
                    variant="outlined"
                    {...rest}
                  />
                )
              })}
              <br />
              {this.props.children}
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            onClick={this.save}
            variant="contained"
          >
            Save details
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

InputCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(InputCard);
