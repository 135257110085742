import __, { setLang, getLang, MetaDesc } from 'utils/lang';
import NavBar, { getNavBarArray } from './NavBar';
import React, { Component } from 'react';
import settings from 'utils/settings';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CookieBanner from './CookieBanner';

class Header extends Component {
  state = {
    sideMenu: false
  }

  render() {
    let headerImage = 'artist';
    let headerColor = settings('headers.colors.artists');
    if(this.props.name in settings('headers.colors')) {
      headerImage = this.props.name;
      headerColor = settings(`headers.colors.${this.props.name}`);
    }
    const name = (__(`headers.${this.props.name}`) || this.props.name).toUpperCase();

    return (
      <>
        {MetaDesc()}
        <CookieBanner />
        <div className="w3-top">
          <div className="w3-bar w3-white w3-card" id="myNavbar">
            <Link to="/main">
              <div
                className="w3-bar-item w3-button w3-wide"
                style={{
                  backgroundPosition: 'center',
                  backgroundSize: '96px',
                  width: '103px',
                  height: '60px',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/static/logo.png)`
                }}
              />
            </Link>
            <button onClick={() => { setLang(getLang() === 'en' ? 'de' : 'en'); this.props.forceUpdate() }} className="w3-bar-item w3-button"><i className="fas fa-globe" /> {getLang() === 'en' ? 'DE' : 'EN'}</button>

            <div className="w3-right w3-hide-small">
              <NavBar />
            </div>
            <div className="w3-center w3-hide-large w3-hide-medium">
              <Link to="/" className="w3-bar-item w3-button w3-wide">SOUNDSGOOD</Link>
            </div>

            <button className="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" onClick={() => this.setState({ sideMenu: true })}>
              <i className="fa fa-bars" />
            </button>
          </div>
        </div>

        {this.state.sideMenu && (
          <nav className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-medium w3-hide-large" id="mySidebar">
            <button onClick={() => this.setState({ sideMenu: false })} className="w3-bar-item w3-button w3-large w3-padding-16">Close ×</button>
            {getNavBarArray().map(([link, title], key) => (
              <Link to={link} key={key} className="w3-bar-item w3-button">{title.toUpperCase()}</Link>
            ))}
          </nav>
        )}

        <div style={{ height: '60px' }} id="home" />

        <div className="bgimg-1 w3-display-container mainSlideDiv headerImg" style={{ backgroundPosition: 'center', backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/static/headers/${headerImage}.jpg)` }}>
          <div className="w3-display-middle w3-center w3-opacity" style={{ minWidth: '100%', height: '60px', backgroundColor: headerColor }} />
          <div className="w3-display-middle w3-hide-small" style={{ color: 'white' }} id="txtT"><h1><b>{name}</b></h1></div>
          <div className="w3-display-middle w3-hide-large w3-hide-medium" style={{ color: 'white' }} id="txtT"><h4><b>{name}</b></h4></div>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  forceUpdate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}

export default Header;