/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputCard from 'components/InputCard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export function Quotes({ user, save }) {
  const saveWrap = ({ quoteDe, quoteEn, quoteByDe, quoteByEn }) => {
    save({
      quote_en: `${quoteByEn}]${quoteEn}`,
      quote_de: `${quoteByDe}]${quoteDe}`
    })
  }
  
  const fields = [
    {
      label: 'English Quote Author',
      key: 'quoteByEn',
      value: user.quote_en.split(']')[0],
      required: true
    },
    {
      label: 'English Quote',
      key: 'quoteEn',
      value: user.quote_en.split(']').slice(1).join('/'),
      required: true,
      multiline: true,
      rows: 10
    },
    {
      label: 'German Quote Author',
      key: 'quoteByDe',
      value: user.quote_de.split(']')[0],
      required: true
    },
    {
      label: 'German Quote',
      key: 'quoteDe',
      value: user.quote_de.split(']').slice(1).join('/'),
      required: true,
      multiline: true,
      rows: 10
    }
  ]
  return (
    <InputCard
      fields={fields}
      save={saveWrap}
      title="Quotes"
    />
  )
}

Quotes.propTypes = {
  save: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export function Information({ user, save }) {
  const fields = [
    {
      label: 'English Information',
      key: 'info_en',
      value: user.info_en,
      required: true,
      multiline: true,
      rows: 12,
      style: { width: '1500px' }
    },
    {
      label: 'German Information',
      key: 'info_de',
      value: user.info_de,
      required: true,
      multiline: true,
      rows: 12,
      style: { width: '1500px' }
    }
  ]
  return (
    <InputCard
      fields={fields}
      save={save}
      title="Artist Page"
    />
  )
}

Information.propTypes = {
  save: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export class AccountDetails extends Component {

  componentWillMount() {
    const genres = ['pop', 'classic', 'jazz', 'rock', 'electro', 'rap', 'hiphop', 'funk', 'cloudpop', 'world'].reduce((acc, g) => ({
      ...acc,
      [g]: this.props.user[g] === '1' || this.props.user[g] === true ? true : false,
    }), {});
    this.setState({ genres })
  }

  genreChange = ({ target }) => {
    this.setState({ genres: { ...this.state.genres, [target.value]: target.checked } })
  }

  save = data => this.props.save({ ...data, ...this.state.genres })

  render() {
    if(!this.state) return <p>Loading...</p>;
    const { user } = this.props;

    const fields = [
      {
        label: 'Display Name',
        key: 'display_name',
        value: user.display_name,
        required: true
      },
      {
        label: 'Youtube',
        key: 'youtube',
        value: user.youtube,
        required: true
      },
      {
        label: 'Change Password',
        type: 'password',
        key: 'password',
        value: ''
      },
      ...['website', 'facebook', 'instagram', 'linkedin', 'spotify'].map(key => ({ label: key.charAt(0).toUpperCase() + key.slice(1), key, value: user[key] })),
      {
        label: 'Contact',
        key: 'contact',
        required: true,
        value: user.contact,
        options: ['ruben', 'friede']
      },
      {
        label: 'Photo Copyright',
        key: 'photo_copyright',
        value: user.photo_copyright
      }
    ]
    return (
      <InputCard
        fields={fields}
        save={this.save}
        title="Artist Details"
      >
        {Object.entries(this.state.genres).map(([g, v], i) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={v}
                color="primary"
                onChange={this.genreChange}
                value={g}
              />
            }
            key={i}
            label={g.charAt(0).toUpperCase() + g.slice(1)}
          />
        ))}
      </InputCard>
    )
  }
}

AccountDetails.propTypes = {
  save: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}
