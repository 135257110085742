import Textify from 'components/Main/Textify';
import PropTypes from 'prop-types';
import __ from 'utils/lang';
import React from 'react';

export default function Impressum({ text }) {
  const split = text.split('###');
  const [ analytics, external, interaction, usage, login] = split.splice(-5);
  const rest = split.join('###');
  
  return (
    <div className="w3-container" style={{ padding: '20px 16px' }}>
      <h3 className="w3-center">{__('impressum.title')}</h3>
      <div style={{ width: '80%', maxWidth: '1100px', margin: 'auto' }}>
        <Textify text={rest} />
        <br />
        <div className="w3-row">
          {[ ['analysen', analytics], ['externe', external], ['interaction', interaction], ['kontrakt', usage], ['login', login] ].map(([ image, text ], key) => (
            <div className="w3-row w3-hide-small" key={key}>
              <div className="w3-quarter" style={{ marginLeft: '50px' }}>
                <br />
                <img src={`${process.env.REACT_APP_BASE_URL}/static/impressum/${image}.PNG`} alt="Analytics" style={{ width: '50%' }} />
              </div>
              <div className="w3-rest">
                <Textify text={'###'.concat(text)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Impressum.propTypes = {
  text: PropTypes.string.isRequired
}
