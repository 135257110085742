import { Avatar, Typography, Button } from '@material-ui/core';
import { withStyles, TextField } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent } from 'components';
import Notify from 'components/Notify';
import { request, getMe } from 'utils/';
import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles';

class Artist extends Component {
  signal = false;

  state = {
    loading: false,
    success: false,
    error: false
  }

  changePassword = async () => {
    const oldPassword = document.getElementById('oldpwd').value;
    const newPassword = document.getElementById('newpwd').value;
    if(!oldPassword || !newPassword) return this.setState({ error: 'Both old and new password should be entered' });
    try {
      this.setState({ loading: true, success: false, error: false });
      let result = await request(`/password/${getMe().id}`, 'POST', { oldPassword, newPassword });
      if(this.signal) {
        if(result.error) {
          return this.setState({ loading: false, error: result.error });
        }
        this.setState({ loading: false, success: true });
      }
    } catch(e) {
      console.error(e) // eslint-disable-line no-console
      if(this.signal)
        this.setState({ loading: false, error: true });
    }
  }

  componentWillMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  render() {
    const { loading } = this.state;
    const { classes, className } = this.props;
    const user = getMe();
    const rootClassName = classNames(classes.root, className);

    if(loading) return (
      <DashboardLayout title="Account">
        <div className={classes.root}>
          {Notify.showLoading()}
        </div>
      </DashboardLayout>
    );

    return (
      <DashboardLayout title="Account">
        {Notify.show(this)}
        <div className={classes.root}>
          <Portlet
            className={rootClassName}
            style={{ width: '650px' }}
          >
            <PortletContent>
              <div className={classes.details}>
                <div className={classes.info}>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: user.displayName}}
                    variant="h2"
                  />
                  <br /><br />
                  <TextField
                    className={this.props.classes.textField}
                    id="oldpwd"
                    label="Old Password"
                    margin="dense"
                    type="password"
                    variant="outlined"
                  />
                  <TextField
                    className={this.props.classes.textField}
                    id="newpwd"
                    label="New Password"
                    margin="dense"
                    type="password"
                    variant="outlined"
                  />
                  <Button
                    className={classes.uploadButton}
                    color="primary"
                    onClick={this.changePassword}
                    variant="text"
                  >
                    Change Password
                  </Button>
                </div>
                <Avatar
                  className={classes.avatar}
                  src={`${process.env.REACT_APP_BASE_URL}/static/musicians/${user.id}.jpg`}
                />
              </div>
            </PortletContent>
          </Portlet>
        </div>
      </DashboardLayout>
    );
  }
}

Artist.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Artist);
