import React from 'react';
import __ from 'utils/lang';
import PropTypes from 'prop-types';

export default function Error({ content }) {
  return (
    <div className="w3-center">
      <br /><br />
      <h1><i className="fas fa-times" style={{ color: 'red' }} /></h1>
      <h3>{content || __('error')}</h3>
      <br />
    </div>
  )
}

Error.propTypes = {
  content: PropTypes.string
}
