import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Input as InputIcon
} from '@material-ui/icons';
import styles from './styles';
import { Redirect } from 'react-router';
import { request } from '../../../../utils';

class Topbar extends Component {
  state = { logout: false }

  render() {
    if(this.state.logout) {
      return (
        <Redirect
          push
          to="/"
        />
      )
    }

    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar
    } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            onClick={onToggleSidebar}
            variant="text"
          >
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            className={classes.title}
            variant="h4"
          >
            {title}
          </Typography>
          <IconButton
            className={classes.signOutButton}
            edge="end"
            onClick={() => request('/logout').finally(() => { ['userId', 'userName', 'userDisplayName'].forEach(k => sessionStorage.removeItem(k)); this.setState({ logout: true }) })}
          >
            <InputIcon />
          </IconButton>
        </Toolbar>
      </div>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

export default withStyles(styles)(Topbar);
